interface IconClickIndicatorProps {
    className?: string,
    onClick?: (e: any) => void
}

export default function IconClickIndicator({ onClick, ...props }: IconClickIndicatorProps) {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} onClick={(e) => onClick ? onClick(e) : null}>
            <path d="M0 12V6.85714H1.71429V10.2857H5.14286V12H0ZM10.2857 5.14286V1.71429H6.85714V0H12V5.14286H10.2857Z" fill="currentColor" />
        </svg>
    )
}