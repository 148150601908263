"use client"

import { useState } from "react"
import Skeleton from "../Skeleton";
import Modal from "../Modal";
import useAuth from "@/hooks/useAuth";

interface AvatarProps {
    url: string;
    size: string;
    border?: string;
    isHighlight?: boolean;
    disableModal?: boolean;
    onClick?: (e: any) => void;
}

export default function Avatar({ url, size = "w-[100px] h-[100px]", border = "border-[3px]", isHighlight = false, disableModal = false, onClick }: AvatarProps) {
    const { isPremiumMember } = useAuth()
    const [imageLoaded, setImageLoaded] = useState(false)
    const [imageLoadedOnModal, setImageLoadedOnModal] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const handleOnModal = (e: any) => {
        onClick?.(e)
        if (!imageLoaded || disableModal) return
        e.preventDefault()
        e.stopPropagation()
        setShowModal(true)
    }

    const closeModal = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setShowModal(false)
        setImageLoadedOnModal(false)
    }

    return (
        <>
            {!imageLoaded && <Skeleton className={`!rounded-full ${size}`} />}
            <img
                src={url}
                alt="profileImage"
                className={`rounded-full ${size} ${isHighlight ? "border-highlight" : "border-grey-1.1"} ${border} object-cover`}
                onLoad={() => setImageLoaded(true)}
                style={{ display: imageLoaded ? 'block' : 'none' }}
                onClick={(e) => handleOnModal(e)}
            />
            <Modal title="Profile image" show={showModal} onClose={(e) => closeModal(e)} titleClassName={isPremiumMember ? "!text-white" : ""} className={`flex flex-col justify-between space-y-6 ${isPremiumMember ? "!bg-primary-5" : ""}`}>
                {!imageLoadedOnModal && <Skeleton className="!h-[200px]" />}
                <img
                    src={url || ''}
                    className={`border object-cover max-h-[400px] ${isHighlight ? "border-highlight" : "border-grey-2.2"}`}
                    onLoad={() => setImageLoadedOnModal(true)}
                    style={{ display: imageLoadedOnModal ? 'block' : 'none' }}
                />
                <div className={`${isPremiumMember ? "bg-primary-1 border-primary-5" : "bg-primary-3 border-primary-4"} w-full rounded-sm flex flex-row justify-center items-center h-[40px] px-[19px] py-[13px] mt-[15px] border`} onClick={(e) => closeModal(e)}>
                    <p className="font-bold text-sm text-white">Close</p>
                </div>
            </Modal>
        </>
    )
}