import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import Xmark from "../../../public/Xmark.svg"
import Image from "next/image";
import ReactDOM from 'react-dom';

interface ModalProps {
    show: boolean,
    title?: string,
    className?: string,
    titleClassName?: string,
    onClose: (e: any) => void,
    children: ReactNode
}

const vModal = {
    show: { opacity: 1, scale: 1, transition: { type: 'spring', duration: 0.3, ease: "easeInOut" } },
    hide: { opacity: 0, scale: 0.5, transition: { type: 'spring', duration: 0.3, ease: "easeInOut" } },
}

export default function Modal({ show = false, title, onClose, className, titleClassName, children }: ModalProps) {
    return ReactDOM.createPortal(
        <AnimatePresence>
            {
                show &&
                <div className="fixed h-full w-full z-50 bg-[#1a1a1a3f] top-0 right-0 flex items-center justify-center !m-0">
                    <motion.div
                        initial={"hide"}
                        animate={show ? "show" : "hide"}
                        variants={vModal}
                        layout
                        exit={{ opacity: 0, scale: 0.5, transition: { type: 'spring', duration: 0.4, ease: "easeInOut" } }}
                        className={`bg-white p-4 rounded-md min-w-64 max-w-sm absolute z-[51] ${className ?? ""}`}
                    >
                        {
                            title &&
                            <div className="flex flex-row justify-between relative">
                                <p className={`font-medium text-base text-primary-1 ${titleClassName ?? ""}`}>{title}</p>
                                <Image src={Xmark} alt="ButtonClose" onClick={(e) => onClose(e)} />
                            </div>
                        }
                        {children}
                    </motion.div>
                    <div className="absolute w-full h-full" onClick={(e) => onClose(e)}></div>
                </div>
            }
        </AnimatePresence>
        , document.body
    )
}