interface IconMessageProps {
    className?: string,
}

export default function IconMessage({ ...props }: IconMessageProps) {
    return (
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
            <path d="M0 9V0.851145C0 0.608713 0.0857408 0.406336 0.257222 0.244012C0.428704 0.0816889 0.642037 0.00035135 0.897222 0H9.10278C9.35833 0 9.57167 0.0813375 9.74278 0.244012C9.91389 0.406687 9.99963 0.609065 10 0.851145V6.5272C10 6.76963 9.91444 6.97219 9.74333 7.13486C9.57222 7.29754 9.3587 7.3787 9.10278 7.37835H1.70944L0 9Z" fill="currentColor" />
        </svg>
    )
}