"use client";
import { useEffect, useState } from "react";
import IconClickIndicator from "../IconClickIndicator";
import IconMessage from "../IconMessage";
import useAuth from "@/hooks/useAuth";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "@/connectors/firebase";
import { Conversation, IMessage, User } from "@/interfaces/firebase";
import Skeleton from "../Skeleton";
import { useMessages } from "@/contexts/messagesContext";
import Avatar from "../Avatar";
import { useQueryClient } from "@tanstack/react-query";

interface MessageCardProps {
  firstName?: string;
  lastName?: string;
  text?: string;
  status?: "pending" | "connected" | "cancelled" | "blocked";
  conversationId?: string;
  theme?: keyof typeof themes;
  image?: string;
  className?: string;
  onClick?: () => void;
  onProfile?: (profileId: string) => void;
}

interface chatsProps {
  [x: string]: Conversation;
}

const themes = {
  default: {
    background: "bg-grey-1",
    border: "border-grey-1.1",
    text: "text-primary-1",
    subText: "text-grey-3",
    iconColor: "text-white",
    iconBackground: "bg-primary-3",
    iconBorder: "border-primary-3.2",
  },
  light: {
    background: "bg-grey-1",
    // border: 'border-primary-4',
    border: "border-grey-1.1",
    text: "text-primary-1",
    subText: "text-grey-3",
    iconColor: "text-primary-3.1",
    iconBackground: "bg-grey-1.1",
    // iconBorder: 'border-primary-4 border-l-0'
    iconBorder: "border-grey-1.1 border-l-0",
  },
};

export default function MessageCard({
  firstName,
  lastName,
  text,
  conversationId,
  image,
  theme = "default",
  status,
  className,
  onProfile,
  onClick
}: MessageCardProps) {
  const { currentUser, isPremiumMember } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [userText, setUserText] = useState("");
  const [profileId, setProfileId] = useState("");
  const { messages, isLoadingMessages } = useMessages()
  const queryClient = useQueryClient();

  useEffect(() => {
    if (firstName) setUserFirstName(firstName);
    if (lastName) setUserLastName(lastName);
    if (text) {
      setUserText(text);
    } else {
      // setUserText("Send the first message");
    }
    if (image) setUserImage(image);
  }, []);

  useEffect(() => {
    try {
      if (isLoadingMessages) return
      if (!conversationId || !messages) {
        setIsLoading(false)
        return
      }
      if (!messages[conversationId]) {
        setUserFirstName("Chat not found");
        return
      }
      let conversations = Object.keys(messages)
        .filter(key => messages[key].context === "user")
        .reduce((obj, key) => {
          obj[key] = messages[key];
          return obj;
        }, {} as chatsProps);
      let user = ""
      if (conversations[conversationId!].isBulletinBoard) {
        user = conversations[conversationId!].participants?.[0] || ""
        setUserFirstName(conversations[conversationId!].name || "");
        setUserLastName("")
        setProfileId(user)
        setIsLoadingUser(false)
      }
      else {
        user = conversations[conversationId!].participants?.find((f) => f != currentUser.id) ?? "";
        findUser(user)
        setProfileId(user)
      }
      // if (!conversations[conversationId!].messages?.length) {
      //   if (!conversations[conversationId!].isBulletinBoard) setUserText("Send the first message")
      //   setIsLoading(false);
      //   return
      // }
      // const allMessages = conversations[conversationId!].messages?.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds) || []
      // findLastMessage(allMessages, conversations[conversationId!].isBulletinBoard)
      setIsLoading(false);
      setTimeout(() => {
        setIsLoadingUser(false)
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setIsLoadingUser(false)
      }, 1000);
      console.log(error)
    }
  }, [messages, isLoadingMessages])

  useEffect(() => {
    if (!conversationId) return
    const cachedMessages = queryClient.getQueryData<Conversation>([
      "chats",
      conversationId,
    ]);
    let conversations = Object.keys(messages)
      .filter(key => messages[key].context === "user")
      .reduce((obj, key) => {
        obj[key] = messages[key];
        return obj;
      }, {} as chatsProps);
    if (cachedMessages?.messages) {
      findLastMessage(cachedMessages.messages.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds), conversations[conversationId!].isBulletinBoard)
    }
  }, [queryClient, conversationId, messages]);

  const findUser = async (userId: string) => {
    try {
      if (!userId) return
      const toRef = doc(db, "users", userId);
      const toSnap = await getDoc(toRef);
      const toUser = toSnap.data() as User;
      if (!toUser) return
      if (toUser.tier === "Sponsor") setIsPremiumUser(true)
      setUserFirstName(toUser.firstName ? toUser.firstName : "");
      setUserLastName(toUser.lastName ? toUser.lastName : "");
      if (toUser.avatarUrl) setUserImage(toUser.avatarUrl);
      setIsLoadingUser(false)
    } catch (error) {
      setIsLoadingUser(false)
      console.log(error)
    }
  }

  const findLastMessage = async (messages: IMessage[], isBulletinBoard?: boolean) => {
    const message = messages.findLast(
      (fl) =>
        fl.data.role == "user" &&
        typeof fl.data.content == "string"
    )?.data.content as string;
    setUserText(message ? message : (isBulletinBoard ? "" : "Send the first message"));
  }

  const handleOnProfile = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    onProfile?.(profileId)
  }

  const handleClickToProfile = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    onProfile?.(profileId)
  }

  return (
    <div
      className={`min-h-[79px] rounded-sm flex justify-between notranslate ${className ?? ""}`}
      onClick={() => onClick?.()}
    >
      <div
        className={`w-[calc(100%_-38px)] py-3 pl-[15px] pr-5 flex justify-between relative border ${isPremiumMember ? (isPremiumUser ? "bg-primary-1 border-highlight" : "bg-primary-1 border-primary-2") : `${themes[theme].border} ${themes[theme].background}`} border-r-0 rounded-bl-sm rounded-tl-sm`}
      >
        <div className="flex flex-row w-full">
          <div className="w-[55px]" onClick={(e) => handleClickToProfile(e)}>
            {
              isLoadingUser ?
                <Skeleton className="w-[55px] h-[55px] !rounded-full" />
                :
                (userImage ? (
                  <Avatar url={userImage} size="!w-[55px] h-[55px] z-10" isHighlight={isPremiumUser} disableModal={true} />
                ) : (
                  <div className="w-[55px]">
                    <div className={`w-[55px] h-[55px] rounded-full ${isPremiumUser ? "border-highlight" : "border-grey-2.2"} border bg-white flex justify-center items-center`}>
                      <p className="text-primary-1 font-bold text-2xl">{`${userFirstName.substring(0, 1).toUpperCase()}${userLastName.substring(0, 1).toUpperCase()}`}</p>
                    </div>
                  </div>
                ))
            }
          </div>
          <div className="ml-[18px] mr-[12px] max-w-[calc(100%_-86px)] w-full">
            {
              isLoadingUser ?
                <Skeleton className="h-[22px]" /> :
                <p
                  className={`${isPremiumMember ? "text-white" : themes[theme].text} font-bold text-base leading-[135%] line-clamp-1`}
                >
                  <span onClick={(e) => handleClickToProfile(e)}>
                    {userFirstName} {userLastName}
                  </span>
                </p>
            }
            {
              isLoadingUser ?
                <Skeleton className="h-[15px]" /> :
                <p
                  className={`${isPremiumMember ? "text-grey-2" : themes[theme].subText} font-medium text-xs leading-[120%] line-clamp-2`}
                >
                  {userText}
                </p>
            }
          </div>
        </div>
        <div className="absolute top-2 right-2 flex justify-center items-center" onClick={(e) => handleOnProfile(e)}>
          <IconClickIndicator className="text-grey-2.2 w-3 h-3" />
        </div>
      </div>
      <div
        className={`w-[38px] rounded-sm rounded-bl-none rounded-tl-none flex items-center justify-center border ${isPremiumMember ? (isPremiumUser ? "border-highlight messagecard-bg-premium" : "bg-primary-2 border-primary-2") : `${themes[theme].iconBackground} ${themes[theme].iconBorder}`}`}
      >
        <IconMessage className={`${isPremiumMember ? (isPremiumUser ? "text-primary-1" : "text-[#96BEFF]") : themes[theme].iconColor}`} />
      </div>
    </div>
  );
}
